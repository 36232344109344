<template>
  <div class="flex">
    <button
      v-for="star in Array(5).keys()"
      :key="star"
      @click="starClicked(star)"
      @mouseover="starMouseHover(star, true)"
      @mouseout="starMouseHover(star, false)"
    >
      <image-kit
        :additional-classes="
          stars && stars[star] && !stars[star].active ? 'hidden' : ''
        "
        :id="`rate-star-${star}`"
        path="/icons/star-full.svg"
        width="40"
        height="40"
        :alt="`Rate the feedback with ${star + 1} star${star > 1 ? 's' : ''}`"
        class="w-full h-[40px] flex-shrink-0 object-cover rounded-4"
      />
      <image-kit
        :additional-classes="
          stars && stars[star] && stars[star].active ? 'hidden' : ''
        "
        :id="`rate-star-${star}`"
        path="/icons/star-empty.svg"
        width="40"
        height="40"
        :alt="`Rate the feedback with ${star + 1} star${star > 1 ? 's' : ''}`"
        class="w-full h-[40px] flex-shrink-0 object-cover rounded-4"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import ImageKit from "~/components/imagekit/ImageKit.vue";

const stars = ref([]);
const selectedRating = ref();

const emit = defineEmits(["select"]);

onMounted(() => {
  stars.value = [0, 1, 2, 3, 4].map((el) => {
    return {
      active: false,
      domEl: document.getElementById(`rate-star-${el}`),
    };
  });
});

const starClicked = (star: number) => {
  selectedRating.value = star;
  starMouseHover(star, false);
  emit("select", star + 1);
};

const starMouseHover = (star: number, over: boolean) => {
  for (let i = 4; i >= 0; i--) {
    if (stars.value && stars.value[i]) {
      if (selectedRating.value >= i || (i > star && over)) {
      } else {
        stars.value[i].active = over;
      }
    }
  }
};
</script>
