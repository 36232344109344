<template>
  <sample-modal
    v-if="isModalVisible"
    overlay-class="modal-opacity-bg overflow-auto"
    container-class="rounded-20 max-h-screen overflow-y-auto bg-grayscale-10 relative"
    bg-color="grayscale-10"
  >
    <template #main>
      <div
        class="h-screen max-h-screen-important sm:h-auto max-h-screen w-screen md:p-12 sm:w-447px flex flex-col sm:rounded-20 py-4 px-6 bg-grayscale-10 justify-start overflow-y-auto text-14 z-20"
      >
        <div class="m-auto md:m-0 flex-row justify-between gap-20">
          <div
            class="flex flex-col mt-2 justify-between gap-4 items-center mb-8"
          >
            <div class="flex flex-row">
              <DialogTitle
                as="h3"
                class="text-16 font-bold font-montserrat text-grayscale-96 my-auto pr-6 text-left pl-1"
              >
                Rate your {{ props.isCall ? "call with" : "feedback from" }}
                {{ proName }}
              </DialogTitle>

              <image-kit
                :path="proProfilePicture || 'placeholder.png'"
                width="56"
                height="56"
                :alt="`Connect ${proName}`"
                additionalClasses="object-cover"
                class="rounded-full mr-5 flex-shrink-0"
              />
            </div>

            <div
              v-if="errorMessages.rating != ''"
              class="text-helpers-validation border border-helpers-validation rounded-4 bg-helpers-validation/10 flex gap-3 py-2 px-3 md:w-full md:justify-center mx-6 mt-8 mb-2 md:mx-0 md:mt-0"
            >
              <i class="icon-info text-16 leading-4" />
              <span class="text-13 leading-140">{{
                errorMessages.rating
              }}</span>
            </div>

            <div class="w-full h-[1px] bg-grayscale-16" />

            <rate-five-stars @select="starsSelected" />

            <p class="text-sm text-grayscale-70 w-full text-left mt-2">
              Additional comments
            </p>

            <input-field
              v-model:value="additionalComments"
              name="additionalComments"
              type="textarea"
              label-class="font-14 text-manatee"
              full-container-class="w-full"
              bg-color="bg-grayscale-7"
              :max-length="200"
            />

            <checkbox
              class="items-start w-full cursor-default"
              @selectionChanged:selected="hideFromProProfileValueChanged"
              :value="`Hide from ${proName} Profile`"
            />
          </div>
          <div class="flex flex-col justify-between gap-2">
            <general-button
              additional-classes="w-full"
              :is-loading="isLoading"
              @click="ratePressed"
              >Rate
            </general-button>
          </div>
        </div>
      </div>
    </template>
  </sample-modal>
</template>

<script setup lang="ts">
import SampleModal from "@/components/modal/SampleModal.vue";
import { DialogTitle } from "@headlessui/vue";
import ImageKit from "@/components/imagekit/ImageKit.vue";
import RateFiveStars from "~/components/layout/inputs/RateFiveStars.vue";
import InputField from "@/components/layout/inputs/InputField.vue";
import Checkbox from "@/components/layout/inputs/Checkbox.vue";
import { Ref, ref, watch } from "vue";
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";
import { useOrdersStore } from "@/store/orders";

const additionalComments = ref("");
const selectedRating = ref();

const emit = defineEmits(["close", "confirm"]);
const errorMessages = ref({ rating: "" });
const props = defineProps({
  isModalVisible: {
    type: Boolean,
    required: true,
  },
  orderId: {
    type: String,
    required: true,
  },
  proName: {
    type: String,
    required: true,
  },
  proProfilePicture: {
    type: String,
  },
  isCall: {
    type: Boolean,
  },
});

const isLoading = ref(false);
const hideFromProProfile = ref(false);
const ordersStore = useOrdersStore();

const clearData = () => {
  selectedRating.value = undefined;
  additionalComments.value = "";
  errorMessages.value.rating = "";
  hideFromProProfile.value = false;
};

const close = () => {
  clearData();
  emit("close");
};
const starsSelected = (stars: number) => {
  selectedRating.value = stars;
  errorMessages.value.rating = "";
};
const ratePressed = async () => {
  try {
    isLoading.value = true;
    const valid = validateForm();
    if (valid.success) {
      const order = await ordersStore.rateOrder(props.orderId, {
        stars: selectedRating.value,
        message: additionalComments.value,
        hideFromProProfile: hideFromProProfile.value,
      });
      emit("confirm", order);
    } else {
      errorMessages.value.rating = valid.message!;
    }
  } catch (e) {
  } finally {
    isLoading.value = false;
  }
};
const validateForm = (): { success: boolean; message?: string } => {
  if (!selectedRating.value) {
    return {
      success: false,
      message: `Please rate your ${props.isCall ? "call" : "feedback"}`,
    };
  }
  return { success: true };
};
const hideFromProProfileValueChanged = (ref: Ref<boolean>) => {
  hideFromProProfile.value = ref.value;
};
</script>
